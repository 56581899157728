import React from "react";
import logo from "../assets/img/black-logo.png";
const BlackLogo = () => {
	return (
		<div className="text-center">
			<img src={logo} className="logo" alt="" />
		</div>
	);
};

export default BlackLogo;
