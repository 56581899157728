import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase"; // Import your Firebase configuration
import { collection, doc, getDoc } from "firebase/firestore";
import {
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
} from "firebase/auth";

import "react-datepicker/dist/react-datepicker.css";
import InputTwo from "../../components/InputTwo";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Firebase email authentication logic

    console.log(email);

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        console.log("User logged in:", userCredential.user);
        setSuccessMessage("Connexion réussie");

        const userId = userCredential.user.uid;
        const usersRef = collection(db, "Users");
        const userDoc = doc(usersRef, userId);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          console.log("User data:", userData);
          // Handle the user data
          navigate(`/vendeur/${userData.username}`);
        } else {
          navigate("/dashboard");
        }

        setErrorMessage("");
      })
      .catch((error) => {
        // Handle login error
        console.log("Login error:", error);
        let message = "";
        switch (error.code) {
          case "auth/wrong-password":
            message = "Mot de passe incorrect.";
            break;
          case "auth/invalid-email":
            message = "Adresse e-mail invalide.";
            break;
          case "auth/user-not-found":
            message = "Utilisateur inconnu.";
            break;
          default:
            message = "Quelque chose n'a pas marché. Veuillez réessayer.";
        }
        setErrorMessage(message);
        setSuccessMessage("");
      });
  };

  return (
    <>
      <section className="login-wrapper">
        <div
          className="login-left"
          style={{
            background: `url(/img/login-bg.png) no-repeat center center / cover`,
          }}
        >
          <img src="/img/login-logo.png" alt="" />
        </div>
        <div className="login-right">
          <div className="login-right-form">
            <div className="user-add-form-header pt-md-3">
              <h4>Se connecter</h4>
              <p style={{ color: "#9C9997" }}>
                Connectez-vous avec votre compte.
              </p>
            </div>
            <br />
            <br className="d-none d-md-block" />
            <form onSubmit={handleSubmit} className="user-add-form login-form">
              <InputTwo
                label={"Email"}
                type="text"
                value={email}
                onChange={(text) => setEmail(text)}
              />
              <InputTwo
                label={"Mot de passe"}
                type="password"
                value={password}
                onChange={(text) => setPassword(text)}
              />
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              {successMessage && (
                <p style={{ color: "green" }}>{successMessage}</p>
              )}
              <br />
              <button
                type="submit"
                className="cmn-btn w-100 mt-4 mx-auto"
                style={{
                  maxWidth: "504px",
                  fontSize: "20px",
                  fontWeight: "700",
                  height: "52px",
                }}
              >
                Se connecter
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
