import React from "react";

const Input = ({ label, type, select, labelMedium, ...rest }) => {
  return (
    <div className="form-group">
      <label className={`form--label ${labelMedium ? "label-medium" : ""}`}>
        {label && label}
      </label>
      {select ? (
        <select
          {...rest}
          className="form--control form-select"
          menuPlacement="auto"
          minMenuHeight={300}
        >
          {select?.map((item, i) => (
            <option
              value={item?.value}
              key={i}
            >
              {item?.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          type={type ? type : "text"}
          className="form-control form--control"
          {...rest}
        />
      )}
    </div>
  );
};

export default Input;
