import ENV from "../../env";

const deleteShowroom = async (showroomId) => {
  const response = await fetch(
    `${ENV.firebaseApiUrl}/showroom/delete/${showroomId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Delete Showroom failed: " + resData.message);
  }

  return resData.showroomId;
};

export default deleteShowroom;
