import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import {
  collection,
  query,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

import { db } from "../../firebase";
import ENV from "../../env";
import avatar from "../../assets/img/avatar-2.png";
import DashboardHeader from "../dashboard/DashboardHeader";

const Dashboard = () => {
  const [snapShot, setSnapShot] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  const correctPassword = "Vangelis#23";

  const checkPassword = (event) => {
    event.preventDefault();
    if(password === correctPassword){
      setAuthenticated(true);
      localStorage.setItem('authenticated', true);
    } else {
      alert('Incorrect password. Please try again.');
      setPassword('');
    }
  };

  const updateSeller = (sellersArray, sellerName, entryType, isConcours) => {
    const sellerIndex = sellersArray.findIndex(item => item.name === sellerName);
  
    if (sellerIndex >= 0) {
      // Seller exists, update their data
      sellersArray[sellerIndex] = {
        ...sellersArray[sellerIndex],
        quantity: sellersArray[sellerIndex].quantity + 1,
        [entryType]: (sellersArray[sellerIndex][entryType] || 0) + 1,
        concours: sellersArray[sellerIndex].concours + (isConcours ? 1 : 0),
      };
    } else {
      // New seller, add them to the array
      sellersArray.push({
        name: sellerName,
        quantity: 1,
        [entryType]: 1,
        concours: isConcours ? 1 : 0,
      });
    }
  
    return sellersArray;
  };
  useEffect(() => {
    const q = query(collection(db, "Customers"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setSnapShot(querySnapshot);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (snapShot) {
        let records = [{}];
        let newArray = [];  // Reset the array instead of copying the existing sellers array
        snapShot.docs.forEach(doc => {
            const data = doc.data();
            console.log(data);
            records.push(data);
            const isConcours = data.title === 'Concours';
            newArray = updateSeller(newArray, data.vendeur, data.type, isConcours);  // Use the helper function
        });

        setCustomers(records);
        setSellers(newArray);  // Update the sellers state with the new array
    }
}, [snapShot]);


  if (!authenticated && !localStorage.getItem('authenticated')) {
    return (
      <div className="h-screen w-screen flex center">
        <form onSubmit={checkPassword}>
          <input 
            type="password" 
            placeholder="Entrer mot de passe" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            className="border border-gray-300 rounded-md w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
          />
          <button 
          type="submit" 
          className="border border-gray-300 rounded-md w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent">Log in</button>
        </form>
      </div>
    )
  }

  console.log(sellers);
  
  return (
    <>
      <DashboardHeader />
      <div
        className="container"
        style={{ "--title:": "#111928", fontFamily: "var(--inter)" }}
      >
        <div className="dashboard-body">
          <div className="d-flex flex-wrap justify-content-between align-items-center gap-3 mb-41">
            <h2 className="title">Résultats vendeurs:</h2>
          </div>
          <br />
          <h2 className="title--small mb-3">Toutes les entrées:</h2>
          <div className="d-flex flex-wrap align-items-center gap-3 mb-41">
            {sellers?.map((item) => (
              <div className="title-info">
                <h6 className="name">{item.name}</h6>
                <span>{item.quantity}</span>
              </div>
            ))}
          </div>          
          <h2 className="title--small mb-3">Données concours:</h2>
          <div className="d-flex flex-wrap align-items-center gap-3 mb-41">
          {sellers?.filter(item => item['concours']).map((item) => (
              <div className="title-info">
                <h6 className="name">{item.name}</h6>
                <span>{item['concours']}</span>
              </div>
            ))}
          </div>   
          <h2 className="title--small mb-3">Entrées QR Code:</h2>
          <div className="d-flex flex-wrap align-items-center gap-3 mb-41">
          {sellers?.filter(item => item['qrcode']).map((item) => (
            <div className="title-info">
              <h6 className="name">{item.name}</h6>
              <span>{item['qrcode']}</span>
            </div>
          ))}
          </div>           
          <h2 className="title--small mb-3">Entrées manuelles:</h2>
          <div className="d-flex flex-wrap align-items-center gap-3 mb-41">
          {sellers?.filter(item => item['vendeur']).map((item) => (
            <div className="title-info">
              <h6 className="name">{item.name}</h6>
              <span>{item['vendeur']}</span>
            </div>
          ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
