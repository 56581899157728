import ENV from "../env";

const updateCustomer = async (
  customerId,
  note,
  date,
  stat,
  action,
  project,
  title,
  selectedShowroom,
  vendeur
) => {
  console.log(vendeur);
  const response = await fetch(`${ENV.firebaseApiUrl}/customer/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      customerId,
      note,
      date,
      stat,
      action,
      project,
      title,
      showroom: selectedShowroom,
      vendeur,
    }),
  });

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Create customer failed: " + resData.message);
  }

  return resData.success;
};

export default updateCustomer;
