import React, { useEffect } from "react";
import { ConfirmationIcon } from "../../components/Icon";

const ConcursConfirmation = () => {
	const [count, setCount] = React.useState(3);

	useEffect(() => {
		if (count > 0) {
			setTimeout(() => setCount(count - 1), 1000);
		}

		if(count === 0){
			window.open("https://www.couleurcuisine.be/", "_self", "");
		}
	}, [count]);

	return (
		<div className="mobile-screen-wrapper">
			<div className="wrapper wrapper-inner">
				<div className="gap-border mb-3"></div>
				<div className="text-center mt-92px">
					<div className="mb-36">
						<ConfirmationIcon />
					</div>
					<h2 className="mb-3" style={{ lineHeight: "1" }}>
						Confirmé
					</h2>
					<div className="subtext subtext-2" style={{ maxWidth: "358px" }}>
						Merci d’avoir participé à notre concours Couleur Cuisine !
						Nous communiquerons la personne gagnante par mail
					</div>
					<div className="subtext subtext-2 mt-2">Vous allez être redirigé dans : {count}</div>
					
				</div>
			</div>
		</div>
	);
};

export default ConcursConfirmation;
