// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBnwA2DgU0n-RGt7WGGen_nrVO7F6N5d8g",
  authDomain: "couleur-cuisine-3f61f.firebaseapp.com",
  projectId: "couleur-cuisine-3f61f",
  storageBucket: "couleur-cuisine-3f61f.appspot.com",
  messagingSenderId: "867376319828",
  appId: "1:867376319828:web:f1c7bd9391eaf34d854cc1",
  measurementId: "G-T1GDH2WKGJ",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
