import ENV from "../env";

const saveGuessCustomer = async (customerId, guess) => {
  const response = await fetch(
    `${ENV.firebaseApiUrl}/customer/guess`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerId,
        guess
      }),
    }
  );

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Create customer failed: " + resData.message);
  }

  return resData.success;
};

export default saveGuessCustomer;