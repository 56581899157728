import ENV from "../env";

const deleteCustomer = async (customerId) => {
  console.log(customerId)
  const response = await fetch(
    `${ENV.firebaseApiUrl}/customer/delete/${customerId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Delete customer failed: " + resData.message);
  }

  return resData.customerId;
};

export default deleteCustomer;
