import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "moment/locale/fr";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.scss";
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardUserDetails from "./pages/dashboard/DashboardUserDetails";
import DashboardScores from "./pages/dashboard/DashboardScores";
import Login from "./pages/dashboard/Login";
import AjoutNote from "./pages/partie-vendeur/AjoutNote";
import Confirmation from "./pages/partie-vendeur/Confirmation";
import PartieVendeur from "./pages/partie-vendeur/PartieVendeur";
import Concours from "./pages/partie-visiteur/Concours";
import ConcoursForm from "./pages/partie-visiteur/ConcoursForm";
import ConcursConfirmation from "./pages/partie-visiteur/ConcursConfirmation";
import PartieVisiteur from "./pages/partie-visiteur/PartieVisiteur";
import Resultats from "./pages/resultats/Resultats";

function App() {

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Login />} />
          {/* Visitors Part */}
          <Route path="/concours-form/:customerId" element={<ConcoursForm />} />
          <Route path="/concours/:customerId" element={<Concours />} />
          <Route
            path="/concours-confirmation"
            element={<ConcursConfirmation />}
          />
          <Route path="/visiteur/:vendeurId" element={<PartieVisiteur />} />

          {/* Sales Part */}
          <Route path="/vendeur/:vendeurId" element={<PartieVendeur />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard-scores" element={<DashboardScores />} />
          <Route
            path="/dashboard-user-details"
            element={<DashboardUserDetails />}
          />
          <Route path="/resultats" element={<Resultats />} />
          <Route path="/ajout-note/:customerId" element={<AjoutNote />} />
          <Route path="/confirmation/:vendeurId" element={<Confirmation />} />
          <Route
            path="/scan-confirmation"
            element={
              <Confirmation>
                Le formulaire a bien été <br /> complété et envoyé
              </Confirmation>
            }
          />
        </Routes>
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
