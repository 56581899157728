import React, { useState, useEffect } from 'react'
import {
	collection,
	query,
	where,
	getDocs,
	onSnapshot,
} from 'firebase/firestore'
import 'moment/locale/fr'
import { db } from '../../firebase'
import { ClearIcon } from '../../components/Icon'

const Resultats = ({ open, setOpen, selectedShowroom }) => {
	const [snapShot, setSnapShot] = useState(null)
	const [sellers, setSellers] = useState([])
	const [customers, setCustomers] = useState([])

	console.log('selectedShowroom', selectedShowroom)

	useEffect(() => {
		if (!selectedShowroom) return

		const q = query(
			collection(db, 'Customers'),
			where('showroom', '==', selectedShowroom)
		)

		const unsuscribe = onSnapshot(q, (querySnapshot) => {
			setSnapShot(querySnapshot)
		})
		return () => {
			unsuscribe()
		}
	}, [selectedShowroom])

	useEffect(() => {
		if (snapShot) {
			let records = [{}]
			let newArray = [] // Reset the array instead of copying the existing sellers array

			snapShot.docs.forEach((doc) => {
				records.push(doc.data())

				const sellerIndex = newArray.findIndex(
					(item) => item.name === doc.data().vendeur
				)

				if (sellerIndex >= 0) {
					//Already in basket
					newArray[sellerIndex] = {
						name: doc.data().vendeur,
						quantity: parseInt(newArray[sellerIndex].quantity + 1),
					}
				} else {
					newArray.push({
						name: doc.data().vendeur,
						quantity: 1,
					})
				}
			})

			// Sort newArray by quantity in descending order
			newArray.sort((a, b) => b.quantity - a.quantity)

			console.log('Save Customers')

			setCustomers(records)
			setSellers(newArray) // Update the sellers state with the sorted array
		}
	}, [snapShot])

	return (
		<>
			<div className={`qr-code-wrapper ${open ? 'open' : ''}`}>
				<div
					className="backdrop backdrop-border"
					onClick={() => setOpen(false)}
					style={{ height: '122px' }}
				></div>
				<div className="qr-code-inner">
					<div className="d-flex justify-content-between align-items-center">
						<h3 className="title--small">Top Vendeurs:</h3>{' '}
						<button
							className="border-0 outline-0 bg-transparent"
							type="button"
							onClick={() => setOpen(false)}
						>
							<ClearIcon />
						</button>
					</div>
					<br />
					<h3 className="name"></h3>

					<div className="d-flex flex-wrap align-items-center gap-3 mb-41">
						{sellers?.map((item) => (
							<div className="title-info">
								<h6 className="name">{item.name}</h6>
								<span>{item.quantity}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	)
}

export default Resultats
