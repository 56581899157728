import * as FileSaver from "file-saver";
import {
  collection,
  onSnapshot,
  query,
  getDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import React, { forwardRef, useEffect, useState } from "react";
import { auth } from "../../firebase";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import XLSX from "sheetjs-style";
import fr from "date-fns/locale/fr";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { subMonths } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import { ArrowBottom, ArrowRight } from "../../components/Icon";
import { db } from "../../firebase";
import DashboardHeader from "./DashboardHeader";

import saveCustomer from "../../utility/saveCustomer";
import deleteCustomer from "../../utility/deleteCustomer";

const Dashboard = () => {
  const navigate = useNavigate();
  const [snapShot, setSnapShot] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [startDate, setStartDate] = useState(subMonths(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [showDelete, setShowDelete] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [showrooms, setShowrooms] = useState([]);
  const [selectedShowroom, setSelectedShowroom] = useState(false);

  const handleClose = () => {
    setIsAlert(false);
    setAlertMessage("");
    setAlertSeverity("info");
  };

  const handleOpenDelete = (index) => {
    setSelectedCustomer(customers[index].id);
    setShowDelete(true);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
    setSelectedCustomer();
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="export-button" onClick={onClick} ref={ref}>
      {value || "Select Date"}
    </button>
  ));

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is authenticated, allow access to protected routes
        console.log("User is authenticated");
        const uid = user.uid;

        // Check if document with ID equal to uid exists in the "Store" collection
        const storeRef = doc(collection(db, "Users"), uid);

        try {
          const docSnapshot = await getDoc(storeRef);
          console.log("docSnapshot");

          if (docSnapshot.exists()) {
            // Document with ID equal to uid exists, navigate to login
            console.log("Is sales");
            // navigate("/login");
          } else {
            console.log("Is admin");
          }
        } catch (error) {
          // Handle any errors that occurred while fetching the document
          console.error("Error getting document:", error);
        }
      } else {
        // User is not authenticated, redirect to login page
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadseetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async (e) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(customers);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "clients" + fileExtension);
  };

  // Helper function to get the month index
  function getMonthIndex(monthString) {
    const months = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];
    return months.findIndex(
      (month) => month.toLowerCase() === monthString.toLowerCase()
    );
  }

  useEffect(() => {
    setIsAlert(true);
    setAlertMessage("Chargement...");
    setAlertSeverity("info");
    const q = query(collection(db, "Customers"));

    const unsuscribe = onSnapshot(q, (querySnapshot) => {
      setSnapShot(querySnapshot);
    });
    return () => {
      unsuscribe();
    };
  }, []);

  useEffect(() => {
    if (snapShot) {
      let records = [];
      let newArray = [];

      snapShot.docs.forEach((doc) => {
        const dateString = doc.data().createdAt;
        const dateParts = dateString.split(" ");
        const day = parseInt(dateParts[0]);
        const month = getMonthIndex(dateParts[1]);
        const year = parseInt(dateParts[2]);

        const recordDate = new Date(year, month, day);
        const isDateInRange = startDate <= recordDate && recordDate <= endDate;
        const isShowroomSelected =
          !selectedShowroom || doc.data().showroom === selectedShowroom; // Check if showroom matches selectedShowroom if selectedShowroom is truthy

        if (isDateInRange && isShowroomSelected) {
          records.push({ ...doc.data(), id: doc.id });

          const sellerIndex = newArray.findIndex(
            (item) => item.name === doc.data().vendeur
          );

          if (sellerIndex >= 0) {
            newArray[sellerIndex] = {
              name: doc.data().vendeur,
              quantity: parseInt(newArray[sellerIndex].quantity + 1),
            };
          } else {
            newArray.push({
              name: doc.data().vendeur,
              quantity: 1,
            });
          }
        }
      });

      setCustomers(records);
      setSellers(newArray);
      setIsAlert(false);
      setAlertMessage("");
      setAlertSeverity("info");
    }
  }, [snapShot, startDate, endDate, selectedShowroom]);

  const fetchShowrooms = async () => {
    try {
      const q = query(collection(db, "Showrooms"));
      let showroomsData = [];

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        console.log("No documents found");
      } else {
        querySnapshot.forEach((doc) => {
          showroomsData.push({
            name: doc.data().name,
            id: doc.id,
          });
        });
        setShowrooms(showroomsData);
      }
      console.log("Doc found");
    } catch (error) {
      console.error("Error fetching showrooms:", error);
    }
  };

  useEffect(() => {
    fetchShowrooms();
  }, []);

  const updateRecordHandler = async (updatedRecord) => {
    console.log(updatedRecord);

    setAlertMessage("Sauvegarde...");
    setIsAlert(true);
    try {
      await saveCustomer(updatedRecord);

      console.log("Customer update successful");
      setAlertSeverity("success");
      setAlertMessage("Sauvegarde réussie.");
      setIsAlert(true);
      // Add success handling code here, if needed
    } catch (error) {
      console.log("Customer update failed: " + error);
      setAlertSeverity("error");
      setAlertMessage("Sauvegarde échouée.");
      setIsAlert(true);
      // Add error handling code here, if needed
    }
  };

  const deleteRecordHandler = async () => {
    console.log(selectedCustomer);

    setShowDelete(false);
    setAlertMessage("Suppression...");
    setIsAlert(true);
    try {
      await deleteCustomer(selectedCustomer);

      setAlertSeverity("success");
      setAlertMessage("Suppression réussie.");
      setIsAlert(true);

      setCustomers(
        customers.filter((customer) => customer.id !== selectedCustomer)
      );
      setSelectedCustomer();
    } catch (error) {
      console.log("Customer update failed: " + error);
      setAlertSeverity("error");
      setAlertMessage("Suppression échouée.");
      setIsAlert(true);
      setSelectedCustomer();
    }
  };

  return (
    <>
      <DashboardHeader />
      <div
        className="container"
        style={{ "--title:": "#111928", fontFamily: "var(--inter)" }}
      >
        <div className="dashboard-body">
          {/* <h3 className="name">Top Vendeurs:</h3>
					<br />
					<div className="d-flex flex-wrap align-items-center gap-3 mb-41">
						{sellers?.map((item) => (
							<div className="title-info">
								<h6 className="name">{item.name}</h6>
								<span>{item.quantity}</span>
							</div>
						))}
					</div> */}

          <br />
          <br />
          <div className="d-flex flex-wrap align-items-center gap-3 mb-3 justify-content-between">
            <h4 className="name m-0">Coordonnées client</h4>
            <div className="d-flex flex-wrap gap-3 justify-content-end">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h6 style={{ marginRight: "10px" }}>Salon</h6>
                <Select
                  value={selectedShowroom}
                  onChange={(e) => setSelectedShowroom(e.target.value)}
                  MenuProps={{
                    disablePortal: true,
                  }}
                >
                  <MenuItem value={false}>Tous</MenuItem>
                  {showrooms.map((showroom) => (
                    <MenuItem key={showroom.id} value={showroom.name}>
                      {showroom.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {/* <select className="export-button">
                <option>Choisir Showroom</option>
                <option>Showroom 1</option>
                <option>Showroom 2</option>
                <option>Showroom 3</option>
                <option>Showroom 4</option>
              </select> */}
              <div
                style={{
                  marginLeft: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h6 style={{ marginRight: "10px" }}>De</h6>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  customInput={<ExampleCustomInput />}
                  locale={fr}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h6 style={{ marginRight: "10px" }}>A</h6>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<ExampleCustomInput />}
                  locale={fr}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <form onSubmit={exportToExcel}>
                <button
                  style={{ marginLeft: "30px" }}
                  type="submit"
                  className="export-button"
                >
                  Exporter en XLSX
                </button>
              </form>
            </div>
          </div>
          <br />
          <div className="__table-container">
            <div className="__table-container-inner">
              <div className="table-responsive">
                <table className="table __table">
                  <thead>
                    <tr>
                      <th>Vendeur</th>
                      <th>Type</th>
                      <th>Salon</th>
                      <th>Nom Client</th>
                      <th>Coordonnées</th>
                      <th>Projet</th>
                      <th>Action</th>
                      <th>Stat</th>
                      <th>Concours</th>
                      <th>Sauvegarder</th>
                      <th>Supprimer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers?.map(
                      (
                        {
                          vendeur,
                          note,
                          name,
                          firstName,
                          mail,
                          phoneNumber,
                          postalCode,
                          stat,
                          project,
                          action,
                          type,
                          date,
                          guess,
                          showroom,
                        },
                        i
                      ) => (
                        <tr key={i}>
                          <td>
                            <div className="title-info">
                              <h6 className="name">{vendeur}</h6>
                              <span>{note}</span>
                            </div>
                          </td>
                          <td>
                            <div className="title-info">
                              <span>{type}</span>
                            </div>
                          </td>
                          <td>
                            <div className="author-info">
                              <span>{showroom}</span>
                            </div>
                          </td>
                          <td>
                            <div className="title-info">
                              <div className="info">
                                <TextField
                                  type="text"
                                  value={name}
                                  sx={{ width: "200px" }}
                                  onChange={(e) => {
                                    const updatedCustomers = [...customers];
                                    updatedCustomers[i].name = e.target.value;
                                    setCustomers(updatedCustomers);
                                  }}
                                />
                                <TextField
                                  type="text"
                                  value={firstName}
                                  sx={{ width: "200px" }}
                                  onChange={(e) => {
                                    const updatedCustomers = [...customers];
                                    updatedCustomers[i].firstName =
                                      e.target.value;
                                    setCustomers(updatedCustomers);
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="author-info">
                              <div className="info">
                                <TextField
                                  type="email"
                                  value={mail}
                                  sx={{ width: "200px" }}
                                  onChange={(e) => {
                                    const updatedCustomers = [...customers];
                                    updatedCustomers[i].mail = e.target.value;
                                    setCustomers(updatedCustomers);
                                  }}
                                />
                                <TextField
                                  type="tel"
                                  sx={{ width: "200px" }}
                                  value={phoneNumber}
                                  onChange={(e) => {
                                    const updatedCustomers = [...customers];
                                    updatedCustomers[i].phoneNumber =
                                      e.target.value;
                                    setCustomers(updatedCustomers);
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="title-info">
                              <select
                                value={project}
                                onChange={(e) => {
                                  const updatedCustomers = [...customers];
                                  updatedCustomers[i].project = e.target.value;
                                  //updateRecordHandler(updatedCustomers[i]);
                                  setCustomers(updatedCustomers);
                                }}
                              >
                                {selectData.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="title-info">
                              <select
                                value={action}
                                onChange={(e) => {
                                  const updatedCustomers = [...customers];
                                  updatedCustomers[i].action = e.target.value;
                                  //updateRecordHandler(updatedCustomers[i]);
                                  setCustomers(updatedCustomers);
                                }}
                              >
                                {actionData.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="title-info">
                              <select
                                value={stat}
                                onChange={(e) => {
                                  const updatedCustomers = [...customers];
                                  updatedCustomers[i].stat = e.target.value;
                                  //updateRecordHandler(updatedCustomers[i]);
                                  setCustomers(updatedCustomers);
                                }}
                              >
                                {statData.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                          <td>
                            <span className="text-title font-bold">
                              {guess}
                            </span>
                          </td>
                          <td>
                            <Button
                              onClick={() => updateRecordHandler(customers[i])}
                              variant="outlined"
                              color="success"
                            >
                              Save
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() => handleOpenDelete(i)}
                              variant="outlined"
                              color="error"
                            >
                              X
                            </Button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <Snackbar
                open={isAlert}
                autoHideDuration={2000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={alertSeverity}
                  sx={{ width: "100%" }}
                >
                  {alertMessage}
                </Alert>
              </Snackbar>
              <Dialog
                open={showDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Supprimer cet enregistrement?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Êtes-vous sûr de vouloir supprimer cet enregistrement ?
                    L'opération est irréversible.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDelete}>Annuler</Button>
                  <Button onClick={deleteRecordHandler} autoFocus>
                    Confirmer
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const selectData = [
  {
    label: "Neuf",
    value: "Neuf",
  },
  {
    label: "Rénovation",
    value: "Rénovation",
  },
  {
    label: "Immobilier",
    value: "Immobilier",
  },
  {
    label: "Inactif",
    value: "Inactif",
  },
  {
    label: "Autre",
    value: "Autre",
  },
];

const actionData = [
  {
    label: "RDV",
    value: "RDV",
  },
  {
    label: "Call",
    value: "Call",
  },
];

const statData = [
  {
    label: "A",
    value: "A",
  },
  {
    label: "B",
    value: "B",
  },
  {
    label: "C",
    value: "C",
  },
  {
    label: "D",
    value: "D",
  },
];

export default Dashboard;
