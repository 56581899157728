import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-alert";
import * as moment from "moment";
import "moment/locale/fr";

import BlackLogo from "../../components/BlackLogo";
import Input from "../../components/Input";
import createCustomer from "../../utility/createCustomer";
import LoadingSpinner from "../../components/LoadingSpinner.js";
import banner from "../../assets/img/banner.png";

const PartieVisiteur = () => {
  const { vendeurId } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [firstName, setFirstName] = useState();
  const [mail, setMail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  const [postalCode, setPostalCode] = useState();
  const [rgpd, setRgpd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  moment.locale("fr-FR");

  const inputChangeHandler = (
    changeEvent: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (changeEvent.target.id) {
      case "Name":
        setName(changeEvent.target.value);
        break;
      case "FirstName":
        setFirstName(changeEvent.target.value);
        break;
      case "Email":
        setMail(changeEvent.target.value);
        break;
      case "PhoneNumber":
        setPhoneNumber(changeEvent.target.value);
        break;

      case "PostalCode":
        setPostalCode(changeEvent.target.value);
        break;
      case "RGPD":
        console.log("RGPD: " + changeEvent.target.value);
        setRgpd(!rgpd);
        break;
      default:
        console.log(`Not found ${changeEvent.target.id}.`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const today = new Date();

    if (rgpd) {
      setIsLoading(true);
      await createCustomer({
        name,
        firstName,
        mail,
        phoneNumber,
        postalCode,
        vendeur: vendeurId,
        type: 'qrcode',
        createdAt: moment(today).format("LLL"),
      })
        .then((customerId) => {
          console.log("Create customer success.");
          console.log(customerId);
          navigate(`/concours/${customerId}`);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("Create customer failed: " + err);
          setIsLoading(false);
        });
    } else {
      alert("Veuillez acceptez les conditons.", {
        type: "error",
      });
    }
  };
  return (
    <>
      <div className="mobile-screen-wrapper">
        <div className="wrapper">
          <div
            className="partie-vendeur-banner"
            style={{
              background: `url(${banner}) no-repeat center center / cover`,
            }}
          >
            <div className="partie-vendeur-banner-content"></div>
          </div>
          <div className={`qr-code-wrapper open`}>
            <div className="backdrop" style={{ height: "122px" }}></div>
            <form onSubmit={handleSubmit} className="qr-code-inner">
              <div className="d-flex gap-4 flex-column">
                <Input
                  label="Nom*"
                  placeholder="Entrez votre nom"
                  type="text"
                  id="Name"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                />
                <Input
                  label="Prénom*"
                  placeholder="Entrez votre prénom"
                  type="text"
                  id="FirstName"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                />
                <Input
                  label="Adresse Mail*"
                  placeholder="@mail.com"
                  type="text"
                  id="Email"
                  onChange={(e) => inputChangeHandler(e)}
                  required="required"
                />
                <Input
                  label="Téléphone*"
                  placeholder="+32 470 000 000"
                  type="text"
                  id="PhoneNumber"
                  required="required"
                  onChange={(e) => inputChangeHandler(e)}
                />
                <Input
                  label="Code Postal*"
                  placeholder="5000"
                  type="text"
                  id="PostalCode"
                  required="required"
                  onChange={(e) => inputChangeHandler(e)}
                />


                <label className="form-check form--check mb-4">
                  <input
                    className="form-check-input"
                    id="RGPD"
                    type="checkbox"
                    onChange={(e) => inputChangeHandler(e)}
                  />
                  <span className="form-check-label">
                    En cochant la présente case, je reconnais avoir pris
                    connaissance du règlement, lequel reprend les dispositions
                    en matière de protection des données à caractère personnel
                    (RGPD) (*Requis)
                  </span>
                </label>
              </div>
              <div className="__content-236 mt-auto mb-39px">
                {isLoading ? (
                  <div className="cmn-btn w-100">
                    <LoadingSpinner height="80%" width="30%" />
                  </div>
                ) : (
                  <button className="cmn-btn w-100" type="submit">
                    Suivant
                  </button>
                )}
              </div>
              <BlackLogo />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};




export default PartieVisiteur;
