import fetch from "node-fetch"; // import the appropriate fetch library
import ENV from "../../env";

const updateShowroom = async (showroomId, name) => {
  console.log(showroomId, name)
  try {
    const response = await fetch(
      `${ENV.firebaseApiUrl}/showroom/update/${showroomId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Update showroom failed: " + response.statusText);
    }

    const resData = await response.json();
    return resData.message;
  } catch (error) {
    console.error("Error updating showroom:", error);
    throw new Error("An error occurred while updating the showroom.");
  }
};

export default updateShowroom;
