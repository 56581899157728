import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import BlackLogo from "../../components/BlackLogo";
import { ConfirmationIcon } from "../../components/Icon";

const Confirmation = ({ children }) => {
	const { vendeurId } = useParams();
	return (
		<div className="mobile-screen-wrapper">
			<div className="wrapper wrapper-inner">
				<div className="gap-border mb-3"></div>
				<div className="text-center mt-92px">
					<div className="mb-36">
						<ConfirmationIcon />
					</div>
					<h2 className="mb-3" style={{ lineHeight: "1" }}>
						Confirmé
					</h2>
					<div className="subtext subtext-2">
						{children ||
							`La note a bien été ajoutée. Vous allez être redirigé vers
						l’écran d’accueil`}
					</div>
				</div>
				<div className="__content-236 mt-auto mb-39px">
					<Link to={vendeurId ? `/vendeur/${vendeurId}` : "/"} className="cmn-btn w-100" type="submit">
						Retour à l’accueil
					</Link>
				</div>
				<BlackLogo />
			</div>
		</div>
	);
};

export default Confirmation;
