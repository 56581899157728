import React from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import BlackLogo from "../../components/BlackLogo";
import { ConfirmationIcon2 } from "../../components/Icon";

const Concours = ({ route, navigation }) => {
  const { customerId } = useParams();
  console.log(customerId);
  const navigate = useNavigate();
  return (
    <>
      <div className="mobile-screen-wrapper">
        <div className="wrapper wrapper-inner">
          <div className="gap-border"></div>
          <div className="__concours-content">
            <div className="__content-268">
              <div className="mb-4">
                <ConfirmationIcon2 />
              </div>
              <h6 className="subtitle mb-3">
                Merci pour votre participation !
              </h6>
              <div className="subtext">
                Un membre de nos équipes Couleur Cuisine vous recontactera par
                mail.
              </div>
            </div>
          </div>
          <div className="__content-316 text-center mt-auto">
            <h6 className="subtitle mb-3">
              Souhaitez-vous participer à notre concours ?
            </h6>
            <div className="subtext">
              Répondez à une question et tentez de remporter un grille-pain de
              la marque Smeg !
            </div>
          </div>
          <form
            onSubmit={() => {
              console.log(customerId);
              navigate(`/concours-form/${customerId}`);
            }}
            className="__content-236 mt-auto mb-39px"
          >
            <button className="cmn-btn w-100" type="submit">
              Participer au concours
            </button>
          </form>
          <BlackLogo />
        </div>
      </div>
    </>
  );
};

export default Concours;
