import ENV from "../../env";

const createUser = async (details) => {
  const response = await fetch(`${ENV.firebaseApiUrl}/user/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user: details,
    }),
  });

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Create user failed: " + resData.message);
  }

  return resData.userId;
};

export default createUser;
