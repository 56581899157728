import React, { useEffect, useRef, useState } from "react";
import { Eye, EyeSlash } from "./Icon";

const InputTwo = ({ type, label, onChange, value }) => {
  const [inputType, setInputType] = useState(type);
  const [active, setActive] = useState(false);
  //const [value, setValue] = useState("");
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      if (ref.current.value || ref.current === document.activeElement) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  }, [value]);
  return (
    <>
      <label
        className={`form-control-item d-block w-100 ${active ? "active" : ""}`}
      >
        <div className="form-label">{label}</div>
        <div className="position-relative">
          <input
            type={type || inputType}
            className="form-control"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              e.target.value ? setActive(true) : setActive(false);
            }}
            onBlur={(e) => {
              e.target.value ? setActive(true) : setActive(false);
            }}
            onFocus={(e) => {
              setActive(true);
            }}
            ref={ref}
          />
          {type === "password" &&
            (inputType === "password" ? (
              <span
                className="eye"
                onClick={() => {
                  if (inputType === "password") {
                    setInputType("text");
                  } else {
                    setInputType("password");
                  }
                }}
              >
                <EyeSlash />
              </span>
            ) : (
              <span
                className="eye"
                onClick={() => {
                  if (inputType === "password") {
                    setInputType("text");
                  } else {
                    setInputType("password");
                  }
                }}
              >
                <Eye />
              </span>
            ))}
        </div>
      </label>
    </>
  );
};

export default InputTwo;
