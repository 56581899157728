import ENV from "../env";

const saveCustomer = async (updatedCustomer) => {
  console.log(updatedCustomer);
  const response = await fetch(`${ENV.firebaseApiUrl}/customer/save`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ updatedCustomer }),
  });

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Create customer failed: " + resData.message);
  }

  return resData.success;
};

export default saveCustomer;
