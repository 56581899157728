import React, { useEffect } from "react";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, query, getDoc, getDocs, doc } from "firebase/firestore";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import InputTwo from "../../components/InputTwo";
import DashboardHeader from "./DashboardHeader";
import LoadingSpinner from "../../components/LoadingSpinner.js";

import createUser from "../../utility/users/createUser";
import deleteUser from "../../utility/users/deleteUser";
import updateUser from "../../utility/users/updateUser";

import createShowroom from "../../utility/showrooms/createShowroom";
import deleteShowroom from "../../utility/showrooms/deleteShowroom";
import updateShowroom from "../../utility/showrooms/updateShowroom";

const DashboardUserDetails = () => {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState();
  const [uid, setUid] = useState();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const [selectedShowroom, setSelectedShowroom] = useState();
  const [showroomName, setShowroomName] = useState("");
  const [successShowroomMessage, setSuccessShowroomMessage] = useState("");
  const [errorShowroomMessage, setErrorShowroomMessage] = useState("");
  const [showrooms, setShowrooms] = useState([]);

  const fetchUsers = async () => {
    try {
      const q = query(collection(db, "Users"));

      let usersData = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        usersData.push({
          email: doc.data().email,
          username: doc.data().username,
          uid: doc.data().uid,
          id: doc.id,
        });
      });

      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchShowrooms = async () => {
    try {
      const q = query(collection(db, "Showrooms"));

      let showroomsData = [];

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        console.log("No documents found");
      } else {
        querySnapshot.forEach((doc) => {
          console.log(doc);
          showroomsData.push({
            name: doc.data().name,
            id: doc.id,
          });
        });
        setShowrooms(showroomsData);
      }
      console.log("Doc found");
    } catch (error) {
      console.error("Error fetching showrooms:", error);
    }
  };
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is authenticated, allow access to protected routes
        console.log("User is authenticated");
        const uid = user.uid;

        // Check if document with ID equal to uid exists in the "Store" collection
        const storeRef = doc(collection(db, "Users"), uid);

        try {
          const docSnapshot = await getDoc(storeRef);
          console.log("docSnapshot");

          if (docSnapshot.exists()) {
            // Document with ID equal to uid exists, navigate to login
            console.log("Is sales");
            navigate("/login");
          } else {
            console.log("Is admin");
          }
        } catch (error) {
          // Handle any errors that occurred while fetching the document
          console.error("Error getting document:", error);
        }
      } else {
        // User is not authenticated, redirect to login page
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    fetchUsers();
    fetchShowrooms();
  }, []);

  const clearMessages = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clearMessages();

    // Firebase email authentication logic
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        // Save the user in the Firestore database
        const createdUser = await createUser({
          uid: user.uid,
          username,
          email,
        });
        if (createdUser) {
          console.log("User created:", user);
          setSuccessMessage("Inscription réussie");
          await fetchUsers();
          setSelectedUser();
          setUsername("");
          setUid();
          setEmail("");
          setPassword("");
        } else {
          setErrorMessage("Sauvegarde de l'utilisateur échouée.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle signup error
        console.log("Signup error:", error.message);
        let message = "";
        switch (error.code) {
          case "auth/email-already-in-use":
            message =
              "L'adresse e-mail est déjà utilisée par un autre utilisateur.";
            break;
          case "auth/invalid-email":
            message = "Adresse e-mail invalide.";
            break;
          case "auth/operation-not-allowed":
            message = "Cette opération n'est pas autorisée.";
            break;
          case "auth/weak-password":
            message = "Mot de passe faible.";
            break;
          default:
            message = "Veuillez réessayer.";
        }
        setErrorMessage(message);
        setIsLoading(false);
      });
  };

  const handleDeleteUser = async (user) => {
    clearMessages();
    setIsLoading(true);
    deleteUser(user)
      .then(async (userDeleted) => {
        if (userDeleted) {
          setSuccessMessage("Utilisateur supprimé");
          fetchUsers();
        } else {
          setErrorMessage("Suppression de l'utilisateur échouée.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle signup error
        console.log("Suppression error:", error.message);
        setErrorMessage("Suppression de l'utilisateur échouée.");
        setIsLoading(false);
      });
  };

  const handleUpdateUser = async (userId) => {
    clearMessages();
    setIsLoading(true);
    updateUser(userId, { email, username, uid })
      .then(async (userUpdated) => {
        if (userUpdated) {
          setSuccessMessage("Utilisateur modfié");
          await fetchUsers();
          setSelectedUser();
          setUsername("");
          setUid();
          setEmail("");
          setPassword("");
        } else {
          setErrorMessage("Modification de l'utilisateur échouée.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle signup error
        console.log("Modification error:", error.message);
        setErrorMessage("Modification de l'utilisateur échouée.");
        setIsLoading(false);
      });
  };

  const handleSubmitShowroom = (e) => {
    e.preventDefault();
    clearMessages();
    setIsLoading(true);
    createShowroom(showroomName)
      .then(async (showroomCreated) => {
        if (showroomCreated) {
          setSuccessShowroomMessage("Showroom créé");
          await fetchShowrooms();
          setSelectedShowroom();
          setShowroomName("");
        } else {
          setErrorShowroomMessage("Modification du showroom échouée.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle signup error
        console.log("Modification error:", error.message);
        setErrorShowroomMessage("Modification du showroom échouée.");
        setIsLoading(false);
      });
  };

  const handleDeleteShowroom = async (showroomId) => {
    clearMessages();
    setIsLoading(true);
    deleteShowroom(showroomId)
      .then(async (showroomId) => {
        if (showroomId) {
          setSuccessShowroomMessage("Showroom supprimé");
        } else {
          setErrorShowroomMessage("Suppression du showroom échouée.");
        }
        await fetchShowrooms();

        setIsLoading(false);
      })
      .catch((error) => {
        // Handle signup error
        console.log("Suppression error:", error.message);
        setErrorShowroomMessage("Suppression du showroom échouée.");
        setIsLoading(false);
      });
  };

  const handleUpdateShowroom = async (showroomId) => {
    clearMessages();
    setIsLoading(true);

    updateShowroom(showroomId, showroomName)
      .then((showroomUpdated) => {
        setSuccessShowroomMessage("Showroom modifié");
        return fetchShowrooms();
      })
      .then(() => {
        setSelectedShowroom(null);
        setShowroomName("");
      })
      .catch((error) => {
        console.log("Modification error:", error.message);
        setErrorShowroomMessage("Modification du showroom échouée.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <DashboardHeader />
      <div
        className="container-fluid"
        style={{
          "--title:": "#111928",
          fontFamily: "var(--inter)",
          maxWidth: "1700px",
        }}
      >
        <div className="dashboard-body">
          <br />
          <div className="d-flex flex-wrap justify-content-between mb-max-sm-0 align-items-center gap-3 mb-41">
            <h4 className="mb-sm-4">Liste des utilisateurs</h4>
          </div>
          <div className="row flex-wrap-reverse justify-content-between">
            <div className="col-lg-8 col-xl-6">
              <div className="__table-container">
                <div className="__table-container-inner">
                  <div className="table-responsive">
                    <table className="table __table">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Username</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.map((user, index) => (
                          <tr key={user.id}>
                            <td>
                              <h6 className="subname">{index + 1}</h6>
                            </td>
                            <td>
                              <h6 className="subname">{user.username}</h6>
                            </td>
                            <td className="pe-0">
                              <div className="d-flex">
                                <div className=" pt-2 me-3">
                                  <span>{user.email}</span>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className="btn __btn btn-outline-base mb-2"
                                    onClick={() => {
                                      clearMessages();
                                      setSelectedUser(user.id);
                                      setEmail(user.email);
                                      setUsername(user.username);
                                      setUid(user.uid);
                                    }}
                                  >
                                    Mettre à jour
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => handleDeleteUser(user)}
                                    className="btn __btn btn-outline-danger"
                                  >
                                    Supprimer
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="pb-5">
                <div className="dashboard-details-card">
                  <div className="user-add-form-header">
                    <h4>
                      {selectedUser
                        ? "Modifier un utilisateur"
                        : "Ajouter un utilisateur"}
                    </h4>
                    <p>
                      {selectedUser
                        ? "Modifier un utilisateur existant."
                        : "Ajouter un nouvel utilisateur à la plateforme."}
                    </p>
                  </div>
                  <br />
                  <br />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (selectedUser) {
                        handleUpdateUser(selectedUser);
                      } else {
                        handleSubmit(e);
                      }
                    }}
                    className="user-add-form"
                  >
                    <InputTwo
                      label={"Nom d'utilisateur"}
                      onChange={(text) => setUsername(text)}
                      value={username}
                      type="text"
                    />
                    <InputTwo
                      label={"Email"}
                      onChange={(text) => setEmail(text)}
                      value={email}
                      type="text"
                    />
                    {!selectedUser && (
                      <InputTwo
                        label={"Mot de passe"}
                        value={password}
                        onChange={(text) => setPassword(text)}
                        type="password"
                      />
                    )}
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                    {successMessage && (
                      <p style={{ color: "green" }}>{successMessage}</p>
                    )}
                    <br />
                    {isLoading ? (
                      <div className="cmn-btn w-100">
                        <LoadingSpinner height="60%" width="10%" />
                      </div>
                    ) : (
                      <button type="submit" className="cmn-btn w-100 mt-4">
                        Sauvegarder
                      </button>
                    )}
                    {selectedUser && (
                      <button
                        type="submit"
                        className="cmn-outline-btn w-100 mt-4"
                        onClick={() => {
                          setSelectedUser();
                          setUsername("");
                          setEmail("");
                          setPassword("");
                          setUid();
                        }}
                      >
                        Annuler
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-body">
          <br />
          <div className="d-flex flex-wrap justify-content-between mb-max-sm-0 align-items-center gap-3 mb-41">
            <h4 className="mb-sm-4">Liste des showrooms</h4>
          </div>
          <div className="row flex-wrap-reverse justify-content-between">
            <div className="col-lg-8 col-xl-6">
              <div className="__table-container">
                <div className="__table-container-inner">
                  <div className="table-responsive">
                    <table className="table __table">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Nom</th>
                        </tr>
                      </thead>
                      <tbody>
                        {showrooms?.map((showroom, index) => (
                          <tr key={showroom.id}>
                            <td>
                              <h6 className="subname">{index + 1}</h6>
                            </td>
                            <td>
                              <h6 className="subname">{showroom.name}</h6>
                            </td>
                            <td className="pe-0">
                              <div className="d-flex">
                                <div>
                                  <button
                                    type="button"
                                    className="btn __btn btn-outline-base mb-2"
                                    onClick={() => {
                                      clearMessages();
                                      setSelectedShowroom(showroom.id);
                                      setShowroomName(showroom.name);
                                    }}
                                  >
                                    Mettre à jour
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleDeleteShowroom(showroom.id)
                                    }
                                    className="btn __btn btn-outline-danger"
                                  >
                                    Supprimer
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="pb-5">
                <div className="dashboard-details-card">
                  <div className="user-add-form-header">
                    <h4>
                      {selectedShowroom
                        ? "Modifier un showroom"
                        : "Ajouter un showroom"}
                    </h4>
                    <p>
                      {selectedShowroom
                        ? "Modifier un showroom existant."
                        : "Ajouter un nouveau showroom à la plateforme."}
                    </p>
                  </div>
                  <br />
                  <br />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (selectedShowroom) {
                        handleUpdateShowroom(selectedShowroom);
                      } else {
                        handleSubmitShowroom(e);
                      }
                    }}
                    className="user-add-form"
                  >
                    <InputTwo
                      label={"Nom du Showroom"}
                      onChange={(text) => setShowroomName(text)}
                      value={showroomName}
                      type="text"
                    />
                    {errorShowroomMessage && (
                      <p style={{ color: "red" }}>{errorShowroomMessage}</p>
                    )}
                    {successShowroomMessage && (
                      <p style={{ color: "green" }}>{successShowroomMessage}</p>
                    )}
                    <br />
                    {isLoading ? (
                      <div className="cmn-btn w-100">
                        <LoadingSpinner height="60%" width="10%" />
                      </div>
                    ) : (
                      <button type="submit" className="cmn-btn w-100 mt-4">
                        Sauvegarder
                      </button>
                    )}
                    {selectedShowroom && (
                      <button
                        type="submit"
                        className="cmn-outline-btn w-100 mt-4"
                        onClick={() => {
                          setSelectedShowroom();
                          setShowroomName("");
                        }}
                      >
                        Annuler
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardUserDetails;
