import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { collection, doc, getDoc } from "firebase/firestore";
import { Alert } from "react-alert";

import img from "../../assets/img/concours.png";
import BlackLogo from "../../components/BlackLogo";
import Input from "../../components/Input";
import LoadingSpinner from "../../components/LoadingSpinner.js";
import saveGuessCustomer from "../../utility/saveGuessCustomer";
import { db } from "../../firebase";

const ConcoursForm = () => {
  const { customerId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState();
  const [guess, setGuess] = useState('0-2kg');

  const navigate = useNavigate();

  const getCustomer = async () => {
    const customerRef = doc(db, "Customers", customerId);

    getDoc(customerRef)
      .then((loadedCustomer) => {
        if (loadedCustomer.data()) {
          setCustomer(loadedCustomer.data());
        } else {
          alert("Ce client n'existe pas.", {
            type: "error",
          });
        }
      })
      .catch((err) =>
        alert("Ce client n'existe pas.", {
          type: "error",
        })
      );
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const inputChangeHandler = (
    changeEvent: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGuess(changeEvent.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (guess) {
      setIsLoading(true);
      const updated = await saveGuessCustomer(customerId, guess);
      if (updated) {
        navigate("/concours-confirmation");
      } else {
        alert("La mise à jour du client n'a pas fonctionnée.", {
          type: "error",
        });
      }
      setIsLoading(false);
    }
  };
  return (
    <div className="mobile-screen-wrapper">
      <form onSubmit={handleSubmit} className="wrapper wrapper-inner">
        <div className="gap-border mb-3"></div>
        <h2 className="mb-3 pt-1" style={{ lineHeight: "1" }}>
          Concours
        </h2>
        <div className="subtitle-1 mb-41">
          Répondez à une question et tentez de remporter un grille-pain de la
          marque Smeg !
        </div>
        <div className="text-center mb-5">
          <img src={img} alt="" />
        </div>
        <Input
          labelMedium
          label="Estimez le poids du grille pain :"
          id="Guess"
          onChange={(e) => inputChangeHandler(e)}
          required="required"
          placeholder="100g"
          type="text"
		  select={selectData}
        />
        <div className="__content-236 mt-auto mb-39px">
          {isLoading ? (
            <div className="cmn-btn w-100">
              <LoadingSpinner height="80%" width="30%" />
            </div>
          ) : (
            <button className="cmn-btn w-100" type="submit">
              Valider
            </button>
          )}
        </div>
        <BlackLogo />
      </form>
    </div>
  );
};

const selectData = [
	{
	  label: "0-2kg",
	  value: "0-2kg",
	},
	{
	  label: "2-3kg",
	  value: "2-3kg",
	},
	{
	  label: "3kg+",
	  value: "3kg+",
	},
  ];

export default ConcoursForm;
