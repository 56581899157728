import fetch from "node-fetch"; // import the appropriate fetch library
import ENV from "../../env";

const updateUser = async (userId, updatedUser) => {
  try {
    const response = await fetch(
      `${ENV.firebaseApiUrl}/user/update/${userId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: updatedUser,
        }),
      }
    );

    if (!response.ok) {
      console.error("Response not ok:", response.statusText);
      throw new Error("Bidouba user failed: " + response.statusText);
    }


    return true;
  } catch (error) {
    console.error("Bidoubib:", error);
    throw new Error("An error occurred while updating the user.");
  }
};

export default updateUser;
