import ENV from "../../env";

const createShowroom = async (name) => {
  const response = await fetch(`${ENV.firebaseApiUrl}/showroom/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      showroom: { name },
    }),
  });

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Create Showroom failed: " + resData.message);
  }

  return resData.showroomId;
};

export default createShowroom;
