import React from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import whiteLogo from '../../assets/img/logo-white.png'
import { SignOut } from '../../components/Icon'
import { signOut } from 'firebase/auth'
import { auth } from '../../firebase'

const DashboardHeader = () => {
	const navigate = useNavigate()

	const handleSignOut = () => {
		// Firebase sign out logic
		signOut(auth)
			.then(() => {
				// Logout successful, redirect to login page
				navigate('/login')
			})
			.catch((error) => {
				// Handle logout error
				console.log('Logout error:', error)
			})
	}

	return (
		<header className="dashboard-header">
			<div className="container-fluid">
				<div className="d-flex flex-wrap align-items-center gap-3">
					<Link to="#" className="me-md-4 me-lg-5 logo">
						<img src={whiteLogo} alt="" />
					</Link>
					<NavLink className="link" to="/dashboard">
						Coordonnées
					</NavLink>
					<NavLink className="link" to="/dashboard-scores">
						Scores vendeurs
					</NavLink>
					<NavLink className="link" to="/dashboard-user-details">
						Configuration
					</NavLink>
					<div className="ms-auto">
						<button type="button" className="sign-out" onClick={handleSignOut}>
							<SignOut />
							Se déconnecter
						</button>
					</div>
				</div>
			</div>
		</header>
	)
}

export default DashboardHeader
