import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QRCode from "qrcode";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";

import { db } from "../../firebase";
import ENV from "../../env";
import BlackLogo from "../../components/BlackLogo";
import { ClearIcon, Clock } from "../../components/Icon";

const ScanCode = ({ open, setOpen }) => {
  const { vendeurId } = useParams();
  const [code, setCode] = useState(false);
  const [qrCode, setQrCode] = useState();
  const navigate = useNavigate();
  const [snapShot, setSnapShot] = useState(null);
  const [customers, setCustomers] = useState([]);

  console.log("setCustomers");
  console.log(customers);

  useEffect(() => {
    const timer = setTimeout(() => {
      open && setCode(true);
    }, 3000);
    return clearTimeout(() => timer);
  }, [open]);

  useEffect(() => {
    const q = query(
      collection(db, "Customers"),
      where("vendeur", "==", vendeurId)
    );

    const unsuscribe = onSnapshot(q, (querySnapshot) => {
      setSnapShot(querySnapshot);
    });
    return () => {
      unsuscribe();
    };
  }, [vendeurId]);

  useEffect(() => {
    if (snapShot) {
      let records = [{}];

      snapShot.docs.forEach((doc) => {
        if (customers.length && !customers.includes(doc.data()) && !doc.data().note) {
          console.log('NO NOTE')
          navigate(`/ajout-note/${doc.id}`);
        }
        records.push(doc.data());
      });
      console.log("Save Customers");
      setCustomers(records);
    }
  }, [snapShot]);

  const generateQr = async () => {
    const qr = await QRCode.toDataURL(`${ENV.hostname}/visiteur/${vendeurId}`, {
      scale: 8,
    });
    setQrCode(qr);
  };

  useEffect(() => {
    generateQr();
  }, []);

  return (
    <>
      <div className={`qr-code-wrapper ${open ? "open" : ""}`}>
        <div className="backdrop" onClick={() => setOpen(false)}></div>
        <div className="qr-code-inner">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="title">QR Code</h2>{" "}
            <button
              className="border-0 outline-0 bg-transparent"
              type="button"
              onClick={() => setOpen(false)}
            >
              <ClearIcon />
            </button>
          </div>
          <div className="my-auto">
            <div className="__content-236 text-center">
              <div className="subtext">
                Scannez le QR Code pour <br /> accéder au formulaire
              </div>
              <img src={qrCode} className="mw-100" alt="" />
            </div>
            <div
              className="text-center text-xs mt-70"
              style={{ visibility: !code ? "hidden" : "" }}
            >
              <Clock /> En attente d’une réponse au formulaire…
            </div>
          </div>
          <BlackLogo />
        </div>
      </div>
    </>
  );
};

export default ScanCode;
