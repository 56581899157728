import ENV from "../../env";

const deleteUser = async (user) => {
  const response = await fetch(`${ENV.firebaseApiUrl}/user/delete/${user.id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user }),
  });

  const resData = await response.json();

  if (!response.ok) {
    throw new Error("Delete user failed: " + resData.message);
  }

  return resData.userId;
};

export default deleteUser;
